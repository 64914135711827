import React from 'react'
import { css, TextBase } from '.'
import { BasicRichText } from '../../frontend/src/components/basic-rich-text'
import { FadeInContainer } from '../../frontend/src/components/fade-in-container'
import { Box, Text } from './primitives'

export const MainHeading = props => (
  //@ts-ignore
  <TextBase
    as={props.as || 'h2'}
    m={0}
    mt={[5, null, null, 7]}
    mb={[4, null, null, 6]}
    fontFamily="default"
    fontWeight={600}
    textAlign="center"
    css={css((theme: any) => ({
      fontSize: `calc(${theme.space[4]} + ${theme.fontSizeFluid[0]})`
    }))}
    {...props}
  />
)

export const MainHeadingWithDescription = ({
  title,
  description,
  isLarge = false
}) => (
  <FadeInContainer>
    <Box px={4}>
      {description ? (
        <MainHeading mb={2}>{title}</MainHeading>
      ) : (
        <MainHeading>{title}</MainHeading>
      )}

      <Box display="flex" justifyContent="center">
        <BasicRichText
          blocks={description}
          TextElement={props => {
            // @ts-ignore
            return (
              <Text
                as="h2"
                m={0}
                textAlign="center"
                mb={[4, null, null, 6]}
                maxWidth={isLarge ? '95ch' : '60ch'}
                textColor="neutral.200"
                fontWeight={300}
                fontSize={3}
                lineHeight={2}
                {...props}
              />
            )
          }}
        />
      </Box>
    </Box>
  </FadeInContainer>
)
