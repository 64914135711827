import styled from '@emotion/styled'
import {
  space,
  layout,
  flexbox,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  position,
  PositionProps,
  overflow,
  OverflowProps,
  system,
  typography,
  TypographyProps,
  maxWidth,
  MaxWidthProps,
  BorderProps,
  border,
  BoxShadowProps,
  boxShadow,
  fontFamily,
  FontFamilyProps
} from 'styled-system'
import { color, ColorProps, TransitionProps, transition } from './styles'
import React from 'react'

export type BoxProps = ColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  OverflowProps &
  TransitionProps &
  BorderProps &
  BoxShadowProps &
  FontFamilyProps

export const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
    minWidth: 0
  },
  space,
  color,
  layout,
  flexbox,
  position,
  overflow,
  transition,
  border,
  fontFamily,
  boxShadow
)

export type TextProps = TypographyProps &
  ColorProps &
  SpaceProps &
  MaxWidthProps &
  TransitionProps

export const TextBase = styled.p<TextProps>(
  typography,
  color,
  space,
  maxWidth,
  transition
)

export const Text: React.FC<TextProps> = props => (
  <TextBase
    lineHeight={0}
    fontFamily="default"
    textColor="neutral.200"
    {...props}
  />
)

export const Flex = styled(Box)({
  display: 'flex'
})

export type SpaceChildrenProps = {
  direction: 'left' | 'right' | 'top' | 'bottom'
  size: number | string
}

export const SpaceChildren = styled.div<SpaceChildrenProps>(
  ({ direction, size, theme }) => ({
    '& > * + *': {
      [`margin${direction.replace(/^\w/, c => c.toUpperCase())}`]:
        typeof size == 'string' ? size : theme.space[size]
    }
  })
)
