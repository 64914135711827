import React from 'react'
import styled from '@emotion/styled'
import { ColorProps, color } from 'styled-system'
import { SpaceChildren, Box } from './primitives'
import { css } from './'
import { buttonReset } from './styles'

const Line: React.FC<
  ColorProps & {
    width: number
    height: number
    index: number
    isOpen: boolean
    yOffset?: number
  }
> = styled('div')(
  color,
  {
    transitionTimingFunction: 'ease',
    transitionDuration: '0.15s',
    transitionProperty: 'transform, opacity'
  },
  ({ isOpen, index, yOffset, width, height }) => ({
    width,
    height,

    ...(isOpen &&
      index == 0 && {
        transform: `translateY(${yOffset}px) rotate(45deg)`
      }),

    ...(isOpen &&
      index == 1 && {
        opacity: 0,
        transform: `translateX(-${width / 2}px)`
      }),

    ...(isOpen &&
      index == 2 && {
        transform: `translateY(-${yOffset}px) rotate(-45deg)`
      })
  })
)

const BoxButton = Box.withComponent('button')

export const HamburgerIcon: React.FC<{
  isOpen: boolean
  lineWidth: number
  lineHeight: number
  lineSpacing: number
  onPress: () => void
  color?: string
}> = ({ lineWidth, lineHeight, lineSpacing, isOpen, onPress, color }) => {
  // we have to move the line up and down to have the rotation center point at
  // the middle of the box. To do that, we calculate the y-center of the box:
  const height = lineSpacing * 2 + lineHeight * 3
  const yOffset = (height - lineHeight) / 2

  return (
    <BoxButton
      css={css({
        ...buttonReset,
        p: 2
      })}
      onClick={() => onPress()}
    >
      <SpaceChildren direction="top" size={`${lineSpacing}px`}>
        {new Array(3).fill(true).map((_, i) => (
          <Line
            key={i}
            index={i}
            bg={color}
            width={lineWidth}
            height={lineHeight}
            isOpen={isOpen}
            yOffset={yOffset}
          />
        ))}
      </SpaceChildren>
    </BoxButton>
  )
}
