import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Button } from '@sitewerk/juraholzbau-design-system/src/button'
import { Text } from '@sitewerk/juraholzbau-design-system'

export const BasicRichText: React.FC<{
  blocks: any
  TextElement?: React.ReactNode
}> = ({ TextElement, ...props }) => (
  <BlockContent
    {...props}
    serializers={{
      types: {
        block: TextElement || Text,
        button: ({ node }) => {
          if (!node || !node.page || !node.page.url) return null
          return (
            <Button to={node.page.url} mt={5} type={node.type}>
              {node.text}
            </Button>
          )
        }
      }
    }}
  />
)
