import React from 'react'
import {
  Flex,
  Box,
  Container,
  Text,
  useSpring,
  animated,
  BoxProps,
  SpaceChildren,
  TextBase,
  MainHeadingWithDescription,
} from '@sitewerk/juraholzbau-design-system'
import Img from 'gatsby-image/withIEPolyfill'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import * as sanity from '../lib/sanity'
import { useHover } from '../lib/hooks'
import { CaptionedImage, ContentGridItem } from '../lib/sanity-types'
import { Link } from 'gatsby'
import { FadeInContainer } from './fade-in-container'

export type ContentItem = {
  _type: 'article' | 'service' | 'project' | 'teamMember'
  title: string
  subtitle: string
  description?: string
  imageAsset: CaptionedImage
  href?: string
}

const Heading1 = TextBase.withComponent('h3')
const Heading2 = TextBase.withComponent('h4')

const ABox = animated(Box)

const Item: React.FC<
  BoxProps & {
    item: ContentItem
    textToTop?: boolean
    imagePosition?: string
  }
> = ({ item, textToTop, imagePosition = '50% 50%', ...props }) => {
  let [hoverRef, isHovered] = useHover<HTMLDivElement>()

  if (!item.href) {
    isHovered = false
  }

  const animateImgProps = useSpring({
    transform: `scale(${isHovered ? 1.2 : 1})`,
  })

  const animateBoxProps = useSpring({
    opacity: isHovered ? 0.7 : 1,
  })

  const Wrapper = item.href ? Link : Box

  if (!item.imageAsset) {
    return null
  }

  return (
    <Wrapper to={item.href} height="100%">
      <Box
        position="relative"
        width={1}
        height="100%"
        overflow="hidden"
        ref={hoverRef}
        {...props}
      >
        <animated.div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: -1,
            ...animateImgProps,
          }}
        >
          {item.imageAsset.asset && (
            <Img
              style={{ height: '100%', width: '100%' }}
              objectFit="cover"
              objectPosition={imagePosition}
              fluid={getFluidGatsbyImage(
                item.imageAsset.asset,
                { maxHeight: 900, maxWidth: 600 },
                sanity.config
              )}
              alt={item.imageAsset.caption}
            />
          )}
        </animated.div>
        <ABox
          position="absolute"
          top={0}
          left={0}
          width={1}
          height="100%"
          style={animateBoxProps}
          bg={!item.imageAsset.asset ? 'neutral.300' : null}
          css={{
            ...(item.imageAsset.asset && {
              background: `linear-gradient(to top, rgba(0, 0, 0, ${
                item._type == 'teamMember' ? 0.3 : 0.7
              }) 10%, transparent)`,
            }),
          }}
        />
        <Box
          position="absolute"
          left={0}
          bottom={!textToTop && 0}
          top={textToTop && 0}
          p={4}
        >
          <Heading2
            fontFamily="default"
            m={0}
            textColor="white"
            fontWeight={600}
            fontSize={0}
          >
            {item.subtitle}
          </Heading2>
          <Heading1
            fontFamily="default"
            lineHeight="1.25"
            m={0}
            textColor="white"
            fontWeight={600}
            fontSize={3}
            mb={1}
          >
            {item.title}
          </Heading1>
          <Heading2
            fontFamily="default"
            m={0}
            maxWidth="30ch"
            textColor="white"
            fontWeight={300}
            fontSize={1}
            mb={1}
          >
            {item.description}
          </Heading2>
        </Box>
      </Box>
    </Wrapper>
  )
}

interface ContentGridProps {
  imagePosition?: string
  text: {
    title: string
    description?: string
  }
  items: ContentItem[]
  reverse?: boolean
}

export const ContentGrid: React.FC<ContentGridProps & BoxProps> = ({
  imagePosition,
  reverse = false,
  text,
  items,
  ...props
}) => {
  return (
    <Box px={4} pb={7} {...props}>
      {text.title && (
        <MainHeadingWithDescription
          title={text.title}
          description={text.description}
        />
      )}

      <Box display={['block', null, 'none']}>
        <SpaceChildren size={4} direction="top">
          {items.map((item, i) => (
            <FadeInContainer key={i}>
              <Item imagePosition={imagePosition} item={item} height="15rem" />
            </FadeInContainer>
          ))}
        </SpaceChildren>
      </Box>

      <FadeInContainer>
        <Container
          height={items.length <= 2 ? '30rem' : '45rem'}
          display={['none', null, 'block']}
        >
          {items.length == 1 && (
            <Item imagePosition={imagePosition} item={items[0]} />
          )}

          {items.length == 2 && (
            <Flex
              height="100%"
              m={-3}
              flexDirection={reverse ? 'row-reverse' : null}
            >
              <Box flex={2} p={3}>
                <Item imagePosition={imagePosition} item={items[0]} />
              </Box>

              <Box flex={3} p={3} m={-3}>
                <Box p={3} width={1} height="100%">
                  <Item imagePosition={imagePosition} item={items[1]} />
                </Box>
              </Box>
            </Flex>
          )}

          {items.length > 2 && (
            <Flex
              height="100%"
              m={-3}
              flexDirection={reverse ? 'row-reverse' : 'row'}
            >
              <Box flex={2} p={3}>
                <Item imagePosition={imagePosition} item={items[0]} />
              </Box>

              <Flex flex={3} flexDirection="column" p={3} m={-3}>
                <Box flex={1} p={3}>
                  <Item
                    imagePosition={imagePosition}
                    item={items[1]}
                    textToTop={true}
                  />
                </Box>

                <Flex
                  p={3}
                  flex={1}
                  flexDirection={['column', null, null, 'row']}
                  m={-3}
                >
                  <Box
                    height="100%"
                    width={items.length == 3 ? 1 : [1, null, null, 3 / 5]}
                    p={3}
                  >
                    <Item imagePosition={imagePosition} item={items[2]} />
                  </Box>
                  {items.length == 4 && (
                    <Box height="100%" width={[1, null, null, 2 / 5]} p={3}>
                      <Item imagePosition={imagePosition} item={items[3]} />
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Container>
      </FadeInContainer>
    </Box>
  )
}

export function transformForGrid(
  contentItems: ContentGridItem[]
): ContentItem[] {
  return contentItems.map((item) => {
    if (item._type == 'service') {
      return {
        _type: item._type,
        title: item.name,
        subtitle: 'Dienstleistung',
        imageAsset: item.previewImage,
        href: item.isRoot
          ? `/dienstleistungen/${item.slug}`
          : `/dienstleistungen/${item.parentServiceSlug}/${item.slug}`,
      }
    }

    if (item._type == 'article') {
      return {
        _type: item._type,
        title: item.title,
        subtitle: 'Blog',
        imageAsset: item.previewImage,
        href: `/blog/${item.slug}`,
      }
    }

    if (item._type == 'project') {
      return {
        _type: item._type,
        title: item.name,
        subtitle: 'Referenz',
        imageAsset: item.previewImage,
        href: `/referenzen/${item.slug}`,
      }
    }
  })
}
