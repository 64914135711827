import React, { useState, useEffect } from 'react'
import { Quote } from '../lib/sanity-types/quote'
import {
  backgroundColor,
  width,
  BackgroundColorProps,
  WidthProps,
  border,
  BorderProps
} from 'styled-system'
import {
  Text,
  styled,
  Box,
  Flex,
  TransparentButton,
  ColorProps,
  color
} from '@sitewerk/juraholzbau-design-system'
import { CircleArrowLeft, CircleArrowRight } from './icons'
import SwipeableViews from 'react-swipeable-views'
import { useInterval } from '../lib/hooks'
import { useInView } from 'react-intersection-observer'

type HeaderFeatureBaseProps = WidthProps & ColorProps & BorderProps

const HeaderFeatureBase = styled.div<HeaderFeatureBaseProps>(
  color,
  width,
  border
)

export const HeaderFeature: React.FC<
  HeaderFeatureBaseProps & { items: Quote[] }
> = ({ items, ...props }) => {
  const [ref, inView] = useInView({ threshold: 1 })
  const [index, setIndex] = useState(0)

  const next = index => (index + 1) % items.length
  const prev = index => (index - 1 == -1 ? items.length - 1 : index - 1)

  const restart = useInterval(
    () => {
      setIndex(next)
    },
    inView ? 5000 : null
  )

  return (
    <HeaderFeatureBase ref={ref} {...props}>
      <Flex justifyContent="center" width={1}>
        <Box
          p={5}
          textColor="neutral.200"
          maxWidth={['30rem', '25rem']}
          flex="1"
        >
          <Text m={0} fontSize={0} fontWeight={600} mb={4}>
            Was unsere Kunden sagen
          </Text>

          <SwipeableViews index={index} animateHeight>
            {items.map(item => (
              <Box key={item._id}>
                <Flex justifyContent="center">
                  <Text
                    m={0}
                    mb={2}
                    fontSize={1}
                    maxWidth="25ch"
                    lineHeight={2}
                    textAlign="center"
                    fontWeight={600}
                  >
                    {item.text}
                  </Text>
                </Flex>

                <Text
                  m={0}
                  textAlign="center"
                  fontSize={0}
                  textColor="neutral.500"
                >
                  {item.from}
                </Text>
              </Box>
            ))}
          </SwipeableViews>

          <Flex height={24} textColor="neutral.400" mt={4}>
            <TransparentButton
              mr={2}
              onClick={() => {
                setIndex(prev)
                restart()
              }}
            >
              <CircleArrowLeft />
            </TransparentButton>
            <TransparentButton
              onClick={() => {
                setIndex(next)
                restart()
              }}
            >
              <CircleArrowRight />
            </TransparentButton>
          </Flex>
        </Box>
      </Flex>
    </HeaderFeatureBase>
  )
}
