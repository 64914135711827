import { useCallback, useState, useRef, useEffect, RefObject } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useComponentSize, { ComponentSize } from '@rehooks/component-size'

export function useToggle(
  initialState = false
): [boolean, React.EffectCallback] {
  const [state, setState] = useState(initialState)
  const toggle = useCallback(() => setState(state => !state), [])
  return [state, toggle]
}

export function useInterval(callback, delay) {
  const savedCallback = useRef<Function>()
  const savedId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      savedId.current = id
      return () => clearInterval(id)
    }
  }, [delay])

  return () => {
    if (savedId.current) {
      clearInterval(savedId.current)
      savedId.current = setInterval(() => savedCallback.current(), delay)
    }
  }
}

export function useHover<T>(): [RefObject<T>, boolean] {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref.current])

  return [ref, value]
}

export function useMeasure<T>(): [RefObject<T>, ComponentSize] {
  const ref = useRef<T>()
  return [ref, useComponentSize(ref)]
}
