import React from 'react'
import { useInView } from 'react-intersection-observer'
import { animated, useSpring } from 'react-spring'

export const FadeInContainer: React.FC<{}> = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  })

  const animateFadeInProps = useSpring({
    transform: `translateY(${inView ? 0 : 3}rem)`,
    opacity: inView ? 1 : 0
  })

  return (
    <animated.div style={animateFadeInProps} ref={ref}>
      {children}
    </animated.div>
  )
}
