import { useSpring, animated } from 'react-spring'
import { useHover } from '../lib/hooks'
import React from 'react'
import { Link } from 'gatsby'
import { css, Flex, TextBase } from '@sitewerk/juraholzbau-design-system'
import { ArrowRight } from './icons'

export const LearnMoreLink = ({ to, children, ...props }) => {
  const [ref, isHovered] = useHover<HTMLDivElement>()

  const animateArrowProps = useSpring({
    transform: `translateX(${isHovered ? 5 : 0}px)`
  })

  return (
    <div ref={ref}>
      <Flex alignItems="center" textColor="brown.0" {...props}>
        {/* 
      // @ts-ignore */}
        <TextBase
          as={Link}
          textColor="inherit"
          fontFamily="default"
          fontWeight={300}
          fontSize={0}
          css={css({
            textDecoration: 'none'
          })}
          pr={2}
          to={to}
          children={children}
        />
        <animated.div style={animateArrowProps}>
          <ArrowRight />
        </animated.div>
      </Flex>
    </div>
  )
}
