import React from 'react'
import { Helmet } from 'react-helmet'
import { SEOMetadata } from '../lib/sanity-types'

export const SEO: React.FC<{ meta: SEOMetadata }> = ({ meta }) => {
  return (
    <Helmet htmlAttributes={{ lang: 'de' }}>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="og:title" content={meta.title} />
      <meta name="og:image" content={meta.ogImageUrl} />
      <meta name="og:description" content={meta.description} />
      <meta name="og:type" content="website" />
    </Helmet>
  )
}
