import React from 'react'
import {
  Box,
  Text,
  Logo,
  LineOverlay,
  NavLinkMobile,
  SpaceChildren
} from '@sitewerk/juraholzbau-design-system'
import { CompanyDetails, Menu } from '../lib/sanity-types'
import { SwissWoodSvg, SocialFacebook, SocialInstagram } from './icons'
import { Link } from 'gatsby'
import { Flex } from '../../../design-system/src'

const BaseFooter = Box.withComponent('footer')

export const Footer: React.FC<{
  companyDetails: CompanyDetails
  menu: Menu
}> = ({ companyDetails, menu }) => (
  <BaseFooter
    bg="neutral.200"
    position="sticky"
    zIndex={0}
    bottom={0}
    textColor="white"
  >
    <LineOverlay bg="dark" />
    <Box py={6} ml="calc(3rem + 5vw)" pr={[5, null, null, 0]}>
      <Logo width="184px" />

      <Text
        fontWeight={600}
        m={0}
        mt={5}
        lineHeight={1}
        fontSize={0}
        textColor="white"
      >
        {companyDetails.street}
        <br />
        {companyDetails.zipCode}
        <br />
        {companyDetails.tel}
        <br />
        {companyDetails.email}
      </Text>

      <Box my={5} position="relative">
        <Box textColor="swissWoodRed" width="4rem">
          <SwissWoodSvg />
        </Box>

        <Box
          display={['flex', null, null, 'none']}
          css={{ alignItems: 'center' }}
          mt={3}
        >
          <Box>
            <a
              href="https://www.facebook.com/juraholzbau/"
              style={{ textDecoration: 'none' }}
            >
              <SocialFacebook width="46px" height="46px" />
            </a>
          </Box>
          <Box ml={3}>
            <a
              href="https://instagram.com/juraholzbau"
              style={{ textDecoration: 'none' }}
            >
              <SocialInstagram width="46px" height="46px" />
            </a>
          </Box>
        </Box>
      </Box>

      <Flex
        position={'relative'}
        flexDirection={['column', null, 'column', 'row']}
      >
        <Text
          m={0}
          mt={3}
          textColor="blackTransparent.100"
          fontSize={0}
          fontWeight={400}
          lineHeight={1}
        >
          &copy; {new Date().getFullYear()} {companyDetails.name}. Alle Rechte
          vorbehalten
        </Text>

        <Box
          position="absolute"
          bottom={0}
          left={0}
          ml="calc(15rem + 25vw)"
          display={['none', null, null, 'block']}
        >
          <SpaceChildren size={3} direction="left" style={{ display: 'flex' }}>
            <a
              href="https://www.facebook.com/juraholzbau/"
              style={{ textDecoration: 'none' }}
            >
              <SocialFacebook width="46px" height="46px" />
            </a>

            <a
              href="https://instagram.com/juraholzbau"
              style={{ textDecoration: 'none' }}
            >
              <SocialInstagram width="46px" height="46px" />
            </a>
          </SpaceChildren>
        </Box>

        <Flex
          position={['unset', null, 'unset', 'absolute']}
          bottom={0}
          left={0}
          ml={[0, null, 0, 'calc(15rem + 39vw)', 'calc(15rem + 48.75vw)']}
          mt={[5, null, null, 3]}
        >
          <Link to="/datenschutzerklärung" style={{ textDecoration: 'none' }}>
            <Text
              as="span"
              m={0}
              mr={3}
              textColor="blackTransparent.100"
              fontSize={0}
              css={{ textTransform: 'uppercase' }}
            >
              Datenschutzerklärung
            </Text>
          </Link>
          <a href="/AEB.pdf" style={{ textDecoration: 'none' }}>
            <Text
              as="span"
              m={0}
              mr={3}
              textColor="blackTransparent.100"
              fontSize={0}
              css={{ textTransform: 'uppercase' }}
            >
              AEB
            </Text>
          </a>
          <a href="/AGB.pdf" style={{ textDecoration: 'none' }}>
            <Text
              as="span"
              m={0}
              textColor="blackTransparent.100"
              fontSize={0}
              css={{ textTransform: 'uppercase' }}
            >
              AGB
            </Text>
          </a>
        </Flex>
      </Flex>
    </Box>

    <Box
      position="absolute"
      top={0}
      left={0}
      py={6}
      ml="calc(18rem + 30vw)"
      display={['none', null, null, 'block']}
    >
      <SpaceChildren direction="top" size={2}>
        {menu.items.map((item, i) => (
          <NavLinkMobile key={i} to={item.pageUrl}>
            {item.title}
          </NavLinkMobile>
        ))}
      </SpaceChildren>
    </Box>
  </BaseFooter>
)
