import * as CSS from 'csstype'
import {
  ResponsiveValue,
  BackgroundColorProps,
  style,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  system,
  Config,
  compose
} from 'styled-system'

export const buttonReset = {
  border: 'none',
  font: 'inherit',
  color: 'inherit',
  backgroundColor: 'transparent',
  cursor: 'pointer',

  '&:focus': {
    outline: 'none'
  },
  '&::-moz-focus-inner': { border: 0 }
}

type TextColorProps = {
  textColor?: ResponsiveValue<CSS.ColorProperty>
}

export type ColorProps = TextColorProps & BackgroundColorProps

let colorConfig: Config = {
  textColor: {
    property: 'color',
    scale: 'colors'
  },

  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors'
  }
}

colorConfig.bg = colorConfig.backgroundColor

export const color = system(colorConfig)

export type TransitionDurationProps = { transitionDuration?: number }

export const transitionDuration = system({
  transitionDuration: {
    property: 'transitionDuration',
    scale: 'transitionDurations',
    transform: (v, scale) => scale[v] + 's'
  }
})

export type TransitionTimingFunctionProps = {
  transitionTimingFunction?: string
}

export const transitionTimingFunction = system({
  transitionTimingFunction: {
    property: 'transitionTimingFunction',
    scale: 'easings'
  }
})

export type TransitionProps = TransitionDurationProps &
  TransitionTimingFunctionProps

export const transition = compose(
  transitionDuration,
  transitionTimingFunction
)
