import React, { useState } from 'react'
import { Global } from '@emotion/core'
import {
  css,
  theme,
  ThemeProvider,
  Box
} from '@sitewerk/juraholzbau-design-system'
import { SEOMetadata, Settings, Menu } from '../lib/sanity-types'
import { ConfiguratorProvider } from './configurator-context'
import { SEO } from './seo'
import { Footer } from './footer'
import './typekit.css'

const LoadableConfigurator = React.lazy(() =>
  import('./configurator').then(m => ({ default: m.Configurator }))
)

export const Layout: React.FC<{
  settings: Settings
  menu: Menu
  seo: SEOMetadata
}> = ({ settings, menu, seo, children }) => {
  const isSSR = typeof window === 'undefined'

  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
          }
        `}
      />

      <SEO meta={seo} />

      <ConfiguratorProvider>
        <ThemeProvider theme={theme}>
          <Box bg="white" minHeight="100vh" position="relative" zIndex={1}>
            {children}
          </Box>

          <Footer companyDetails={settings.company} menu={menu} />

          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <LoadableConfigurator />
            </React.Suspense>
          )}
        </ThemeProvider>
      </ConfiguratorProvider>
    </>
  )
}
