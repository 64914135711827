const baseUnit = 1

const theme = {
  fontSizes: [0.875, 1, 1.125, 1.25, 1.563, 1.875, 2.188, 2.8125].map(
    n => n + 'rem'
  ),

  fontSizeFluid: ['3.5vw', '4vw'],

  lineHeights: [1, 1.4, 1.6, 1.8],
  transitionDurations: [0.15, 0.25, 0.4],

  colors: {
    blue: {
      0: '#a2b4c5'
    },

    brown: {
      0: '#b98c69'
    },

    neutral: {
      100: '#000',
      200: '#0b0f1c',
      300: '#1d1d1b',
      400: '#b0b4b5',
      500: '#d8d8d8',
      600: '#e8e8e8',
      700: '#f6f5f5',
      800: '#fff'
    },

    success: '#90ee90',

    swissWoodRed: '#e2000d',
    white: '#fff',
    blackTransparent: {
      100: 'rgba(255, 255, 255, 0.3)',
      200: 'rgba(255, 255, 255, 0.1)'
    },

    whiteTransparent: {
      100: 'rgba(29, 29, 27, 0.05)'
    }
  },

  breakpoints: ['40em', '52em', '64em', '80em'],

  space: [
    0,
    baseUnit * 0.25,
    baseUnit * 0.5,
    baseUnit * 0.75,
    baseUnit * 1.25,
    baseUnit * 2,
    baseUnit * 3.25,
    baseUnit * 5.25
  ].map(f => f + 'rem'),

  fonts: {
    default: 'neue-haas-unica, sans-serif'
  },

  shadows: ['0 6px 30px -10px rgba(0, 0, 0, 0.2)'],

  easings: {
    // for incoming elements
    decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
    // for elements exiting a screen
    acelerate: 'cubic-bezier(0.4, 0.0, 1, 1)'
  }
}

export default theme
