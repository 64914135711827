import { css, ColorProps, styled, Box, color } from '.'
import React, { useState, useEffect } from 'react'
import { GatsbyLinkProps, Link } from 'gatsby'
import { TypographyProps, typography, space, SpaceProps } from 'styled-system'
import { useSpring, animated } from 'react-spring'
import { useHover } from '../../frontend/src/lib/hooks'

type NavLinkProps = GatsbyLinkProps<{}> &
  TypographyProps &
  ColorProps &
  SpaceProps

const ABox = animated(Box)

const NavLinkBase = styled(Link)<NavLinkProps>(typography, color, space)

export const NavLinkDesktop = props => {
  const [ref, isHovered] = useHover<HTMLDivElement>()

  //@ts-ignore
  const animateHoverProps = useSpring({
    from: {
      transform: 'translateX(-105%)'
    },
    to: isHovered && [
      { transform: `translateX(100%)`, immediate: false },
      { transform: 'translateX(-105%)', immediate: true }
    ]
  })

  return (
    <Box position="relative" display="inline-block" ref={ref} overflow="hidden">
      <ABox
        css={css({
          transformOrigin: 'right',
          position: 'absolute',
          display: 'inline-block',
          height: '100%',
          width: '100%',
          bg: props.color,
          zIndex: -1
        })}
        style={animateHoverProps}
      />

      <NavLinkBase
        fontFamily="default"
        fontSize={1}
        fontWeight={600}
        css={css({
          color: props.color,
          overflow: 'hidden',
          textDecoration: 'none',
          padding: '2px 0'
        })}
        {...props}
      />
    </Box>
  )
}

export const NavLinkMobile = props => (
  <NavLinkBase
    css={css({
      fontFamily: 'default',
      textDecoration: 'none',
      fontSize: 4,
      color: 'white',
      display: 'block',
      fontWeight: 600
    })}
    {...props}
  />
)
