import styled from '@emotion/styled'
import { Box, BoxProps } from './primitives'
import React from 'react'
import { space, SpaceProps } from 'styled-system'
import { css } from '.'
import { Link, GatsbyLinkProps } from 'gatsby'
import { buttonReset } from './styles'

const LinkBase = styled(Link)(space)
const ButtonBase = LinkBase.withComponent('button')

export type ButtonProps = Partial<GatsbyLinkProps<{}>> &
  SpaceProps & {
    type: 'primary' | 'secondary' | 'primary-success'
    fullWidth?: boolean
    disabled?: boolean
  }

export const TransparentButton = styled(Box)(buttonReset).withComponent(
  'button'
)

export const Button: React.FC<ButtonProps> = ({
  disabled = false,
  type,
  fullWidth = false,
  to,
  ...props
}) => {
  const style = css(theme => ({
    transitionDuration: `${theme.transitionDurations[0]}s`,
    transitionTimingFunction: 'ease',
    display: 'inline-block',
    textDecoration: 'none',
    fontFamily: 'default',
    lineHeight: 1,
    cursor: 'pointer',
    fontSize: 0,
    borderStyle: 'solid',
    borderColor: 'neutral.700',
    borderWidth: '1px',
    borderRadius: '9999px',
    fontWeight: 600,
    py: 3,
    px: 4,
    boxShadow: 0,
    transitionProperty: 'background-color, color, transform',
    transform: 'translateY(-2px)',

    ...(fullWidth && {
      width: '100%',
      borderRadius: 0,
      textAlign: 'center',
      display: 'block'
    }),

    ...(type == 'primary' && {
      bg: 'white',
      color: 'neutral.200'
    }),

    ...(type == 'primary-success' && {
      bg: 'success',
      color: 'neutral.100'
    }),

    ...(type == 'secondary' && {
      bg: 'transparent',
      color: 'white'
    }),

    ':hover': {
      color: 'neutral.300',
      bg: 'white'
    },

    ':active': {
      transform: 'translateY(0px)'
    },

    ...(disabled && {
      color: 'neutral.600',

      ':hover': {
        color: 'neutral.600'
      }
    })
  }))

  if (to) {
    return <LinkBase css={style} to={to} {...props} />
  }

  return <ButtonBase disabled={disabled} css={style} {...props} />
}
