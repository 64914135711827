import { createContext, useContext, useState } from 'react'
import React from 'react'

export const ConfiguratorContext = createContext<
  | {
      startPath: string[]
      isOpen: boolean
      openConfigurator: (path: string[]) => void
      setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
      setPath: React.Dispatch<React.SetStateAction<string[]>>
    }
  | undefined
>(undefined)

export function useConfiguratorContext() {
  const context = useContext(ConfiguratorContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }

  return context
}

export const ConfiguratorProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [startPath, setPath] = useState<string[]>([])

  const openConfigurator = (path: string[]) => {
    setIsOpen(b => !b)
    setPath(path)
  }

  return (
    <ConfiguratorContext.Provider
      value={{ startPath, isOpen, openConfigurator, setIsOpen, setPath }}
    >
      {children}
    </ConfiguratorContext.Provider>
  )
}
