import { Box, css } from '.'
import React from 'react'

export const LineOverlay: React.FC<{ bg: 'dark' | 'light' }> = ({ bg }) => (
  <Box
    position="absolute"
    top={0}
    left={0}
    width={1}
    zIndex={-1}
    height="100%"
    css={css((theme: any) => ({
      background: `linear-gradient(90deg, ${(bg == 'dark' &&
        theme.colors.blackTransparent[200]) ||
        (bg == 'light' &&
          theme.colors
            .whiteTransparent[100])} 1%, transparent 1%) 1px 0, transparent`,
      backgroundSize: 'calc(3rem + 5vw) 1px'
    }))}
  />
)
